.gWrapper {
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mainMenuMobile {
    display: none;
}

.mainHeader {
    width: 100%;
    height: 100vh;
    background: url("../img/3.jpg") center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    user-select: none;
    text-align: center;
    z-index: 3;
}

.mainHeaderTitleContainer {
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0) 100%);
    padding: 20px 100px;
}

.mainHeaderTitle {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    padding: 0 30px;
}

.mainHeaderDescription {
    font-size: 28px;
    color: #fff;
    font-weight: 200;
    padding: 0 30px;
    margin-top: 10px;
}

.mainContent {
    width: 100%;
    display: flex;
    z-index: 5;
}

.mainMenu {
    position: fixed;
    width: 100%;
    height: 60px;
    background: rgba(0,0,0,0.7);
    padding: 0 30px;
    cursor: default;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 15;
}

.mainMenuLogoContainer {
    display: flex;
    align-items: center;
}

.mainMenuLogo {
    height: 60px;
    width: 60px;
    background: url("../img/logo.png") center center no-repeat;
    background-size: cover;
}

.mainMenuLogoTitle {
    font-family: Caveat, cursive;
    font-size: 24px;
    line-height: 14px;
    color: #fff;
    text-align: center;
    padding-left: 5px;
    cursor: pointer;
}

.mainMenuPhone {
    font-family: Caveat, cursive;
    font-size: 20px;
    cursor: pointer;
}

.mmpPhone {
    color: #fff;
    transition: color 300ms ease-in-out;
}

.mmpPhone:hover {
    color: #aaa;
}

.mainMenuLeftContainer,
.mainMenuRightContainer {
    display: flex;
    align-items: center;
}

.mainMenuPosition {
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    transition: color 300ms ease-in-out;
}

.mainMenuPosition:hover {
    color: #aaa;
}





/* CATALOG */

.catalogContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
    cursor: default;
}

.catalogTitleContainer {
    position: relative;
    width: 1200px;
    height: 500px;
    background-size: cover;
    cursor: pointer;
    margin-bottom: 70px;
}

.ctcBackground {
    position: absolute;
    width: 100%;
    height: 500px;
    background: url("../img/1.jpg") center center no-repeat;
    background-size: cover;
    z-index: 1;
    border: 1px solid #ccc;
}

.ctcContent {
    position: absolute;
    width: 100%;
    height: 500px;
    z-index: 2;
    color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0);
    transition: background-color 300ms ease-in-out;
    text-align: center;
}

.ctcTitle {
    padding: 0 40px;
    font-size: 40px;
    font-weight: 500;
}

.ctcDescription {
    padding: 20px 10% 0;
    font-size: 24px;
    font-weight: 300;
}

.catalogTitleContainer:hover .ctcContent {
    color: #000;
    background-color: rgba(255,255,255,0.7);
}

.catalogMainContainer {
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 74px;
    transition: opacity 300ms ease-in-out, height 300ms ease-in-out;
}

.catalogPageContainer {
    width: 1200px;
    height: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 74px;
    transition: opacity 300ms ease-in-out, height 300ms ease-in-out;
}

.catalogMainBlock {
    display: flex;
    flex-direction: column;
}

.catalogMainItem {
    width: 350px;
    height: 350px;
    position: relative;
    cursor: pointer;
}

.catalogMainBlock:nth-child(3n+2) {
    margin: 0 74px;
}

.catalogBackButton {
    height: 350px;
    min-width: 350px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 24px;
    transition: background-color 300ms ease-in-out;
}

.catalogBackButton:hover {
    background-color: #eee;
}

.cmiBackground {
    position: absolute;
    width: 350px;
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
}

.cmiContent {
    position: absolute;
    width: 350px;
    height: 350px;
    color: transparent;
    background-color: rgba(255,255,255,0);
    z-index: 2;
    transition: background-color 300ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.cmiAddButton {
    width: 350px;
    height: 45px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 300ms ease-in-out;
}

.cmiAddButton:hover {
    background-color: #eeeeee;
}

.catalogMainItem:hover .cmiContent {
    color: #000;
    background-color: rgba(255,255,255,0.7);
}




/* CATALOG ITEM */

.ciWrapper {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: opacity 300ms ease-in-out, z-index 300ms ease-in-out, top 300ms ease-in-out;
}

.ciMiniatureContainer {
    width: 100%;
    height: 100px;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
}

.ciMiniatureItem {
    width: 80px;
    height: 80px;
    border: 1px solid #666;
    margin: 0 5px;
    cursor: pointer;
}

.ciCloseButton {
    display: none;
}

.ciMiniatureImage {
    width: 80px;
    height: 80px;
}

.ciContainer {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.7);
    position: relative;
    margin-bottom: 120px;
}

.ciImage {
    max-height: calc(95vh - 100px);
    max-width: 95vw;
    border-radius: 5px;
}

.ciTitle {
    position: absolute;
    top: 20px;
    padding: 5px 0;
    width: 40%;
    font-size: 24px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,0.5) 80%, rgba(0,0,0,0) 100%);
}

.ciPrice {
    padding: 5px 30px;
    position: absolute;
    top: 64px;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,0.5) 80%, rgba(0,0,0,0) 100%);
}

.ciButton {
    position: absolute;
    bottom: 100px;
    height: 70px;
    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.6);
    cursor: pointer;
    outline: none;
    opacity: 0;
    transition: background-color 300ms ease-in-out, opacity 300ms ease-in-out;
}

.ciButton:hover {
    background-color: rgba(255,255,255,0.8);
}

.ciDescription {
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    padding: 10px 0;
    opacity: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,0.5) 80%, rgba(0,0,0,0) 100%);
    width: 90%;
    transition: opacity 300ms ease-in-out;
}

.ciContainer:hover .ciButton,
.ciContainer:hover .ciTitle,
.ciContainer:hover .ciDescription,
.ciContainer:hover .ciPrice {
    opacity: 1;
}

.ciArrow {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: rgba(255,255,255,0.1);
    top: 50%;
    border-radius: 50%;
    background-image: url("../img/arrow.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-color 300ms ease-in-out;
    cursor: pointer;
    z-index: 15;
    opacity: 0.3;
}

.ciArrow:hover {
    background-color: rgba(255,255,255,0.6);
}

.ciLeftArrow {
    transform: translateY(-50%);
    left: 50px;
}

.ciRightArrow {
    transform: translateY(-50%) rotate(180deg);
    right: 50px;
}




    /* FOOTER */

.footer {
    background: #ccc;
    display: flex;
    height: 300px;
    align-items: flex-end;
    padding: 0 50px;
    cursor: default;
    background: url("../img/footer.svg") no-repeat;
    background-size: 100%;
    z-index: 10;
}

.footerContactsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footerContacts,
.footerInstagram {
    display: flex;
    height: 200px;
}

.footerContacts {
    justify-content: space-between;
    align-items: center;
    padding-right: 220px;
}

.footerContactsTitle {
    font-size: 24px;
    padding-left: 50px;
    font-style: italic;
}

.footerContactsItem {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    cursor: pointer;
    font-style: italic;
}

.footerContactsItem:hover {
    font-weight: 400;
}

.footerInstagram {
    flex-direction: column;
    font-style: italic;
    font-size: 18px;
}

.footerInstagramTitle {
    color: #000;
    transition: color 300ms ease-in-out;
}

.footerInstagramTitle:hover {
    color: #666;
}

.footerInstagramPhotos {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    width: 100%;
}

.fciTime {
    cursor: default;
}

.fciLink {
    color: #000;
    transition: color 300ms ease-in-out;
}

.fciLink:hover {
    color: #666;
}

.fipItem {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    filter: opacity(100%);
    transition: filter 300ms ease-in-out;
}

.fipItem:hover {
    filter: opacity(30%);
}

.fip1 {
    background: url("../img/insta/1.jpg") center center no-repeat;
    background-size: cover;
}

.fip2 {
    background: url("../img/insta/2.jpg") center center no-repeat;
    background-size: cover;
}

.fip3 {
    background: url("../img/insta/3.jpg") center center no-repeat;
    background-size: cover;
}

.fip4 {
    background: url("../img/insta/4.jpg") center center no-repeat;
    background-size: cover;
}



/* ORDER WINDOW */

.orderWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease-in-out, z-index 300ms ease-in-out, top 300ms ease-in-out;
}

.orderSuccess {
    font-size: 24px;
    line-height: 30px;
    width: 60%;
    text-align: center;
}

.orderContainer {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 70%;
    text-align: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.7);
}

.orderTitle {
    font-size: 24px;
}

.orderDescription {
    width: 60%;
    font-weight: 400;
    padding: 10px 0;
    line-height: 20px;
}

.aboutDescription {
    height: 100%;
    display: flex;
    align-items: center;
}

.servicesContainer {
    overflow-y: auto;
    padding: 10px;
}

.servicesContainer .orderDescription {
    width: 100%;
    text-align: left;
}

.servicesWrapper .cartButton {
    margin: 20px 0;
}

.servicesWrapper .orderContainer {
    padding: 0;
}

.orderInput {
    width: 60%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 5px;
    margin: 5px 0;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    text-align: center;
}

.orderWishInput {
    height: 150px;
    width: calc(60% - 6px);
    resize: none;
    outline: none;
}

.orderButtonsContainer {
    width: 60%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.orderButton {
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #e8f4ff;
    cursor: pointer;
    outline: none;
}

.orderButtonSend:hover {
    background: #96ff96;
}

.orderButtonClose:hover {
    background: #ff8686;
}



/* CART */

.cartWrapper {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 26;
    transition: opacity 300ms ease-in-out, z-index 300ms ease-in-out, top 300ms ease-in-out;
}

.cartContainer {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    min-height: 300px;
    max-height: 80%;
    text-align: center;
    z-index: 26;
    box-shadow: 0 0 20px rgba(0,0,0,0.7);
}

.cartTitle {
    font-weight: 500;
    font-size: 27px;
    height: 30px;
}

.cartItemsContainer {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 10px 20px;
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;
}

.cartItemBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.cartItemBlock:last-child {
    border: 0;
}

.cibPicture {
    min-width: 100px;
    height: 100px;
    background-position: center center;
    background-size: cover;
}

.cibTitle {
    padding: 0 5px;
}

.cibRemoveButton {
    min-width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    outline: none;
    transition: 300ms background-color ease-in-out;
    cursor: pointer;
}

.cibRemoveButton:hover {
    background-color: #ff8181;
}

.cartButtonsContainer {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.cartButton {
    width: 220px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 20px;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 300ms ease-in-out;
}

.cartButton:hover {
    background-color: #eee;
}

.loadingContainer {
    width: 200px;
    height: 200px;
    background: url("../img/loading.png") center center no-repeat;
    background-size: cover;
    animation: loading 1s infinite linear;
}

@keyframes loading {
    0%   {transform: rotate(0deg);}
    100%   {transform: rotate(360deg);}
}



/* PULSE BUTTON */

.globalContactsContainer {
    position: fixed;
    bottom: 60px;
    left: 60px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(10, 10, 10, 0.1);
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.globalContactsBlock {
    position: relative;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    background: #0075a6;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 300ms ease-in-out, height 300ms ease-in-out, background 300ms ease-in-out;
}

.globalContactsImage {
    width: 25px;
    height: 25px;
    background: url("../img/phone.png") center center no-repeat;
    background-size: 25px;
    filter: invert(1);
}

.globalContactsPulse {
    height: 56px;
    width: 56px;
    border: 1px solid #0075a6;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        height: 56px;
        width: 56px;
        border: 1px solid #0075a6;
    }
    40% {
        height: 120px;
        width: 120px;
        border: 1px solid transparent;
    }
    100% {
        border: 1px solid transparent;
    }
}




@media screen and (max-width: 1550px) {
    .footerContacts {
        padding-right: 80px;
    }
}

@media screen and (max-width: 1365px) {
    .fipItem {
        width: 100px;
        height: 100px;
    }
    .footerContacts {
        padding-right: 40px;
    }
    .orderDescription,
    .orderInput {
        width: 80%;
    }
    .cartContainer,
    .orderContainer {
        width: 80%;
    }
}

@media screen and (max-width: 1240px) {
    .fipItem {
        width: 80px;
        height: 80px;
    }
    .footerContacts {
        padding-right: 30px;
    }
    .footerInstagram {
        height: 160px;
    }
    .catalogMainItem,
    .cmiBackground,
    .cmiContent {
        width: 300px;
        height: 300px;
    }
    .catalogBackButton {
        min-width: 300px;
        min-height: 300px;
    }
    .cmiAddButton {
        width: 300px;
    }
    .catalogMainContainer,
    .catalogPageContainer {
        width: 1000px;
        row-gap: 50px;
    }
    .catalogTitleContainer {
        width: 1000px;
    }
    .catalogMainBlock:nth-child(3n+2) {
        margin: 0 50px;
    }
    .cartContainer,
    .orderContainer {
        width: 90%;
    }
}

@media screen and (max-width: 1170px) {
    .fciTime {
        display: none;
    }
}

@media screen and (max-width: 1020px) {
    .catalogTitleContainer {
        width: 650px;
    }
    .catalogMainContainer {
        grid-template-columns: repeat(2, 1fr);
        width: 700px;
    }
    .catalogPageContainer {
        width: 650px;
    }
    .catalogPageContainer {
        grid-template-columns: repeat(2, 1fr);
        width: 700px;
    }
    .catalogMainBlock:nth-child(3n+2) {
        margin: 0 auto;
    }
    .catalogMainBlock {
        margin: 0 auto;
    }
    .catalogMainItem {
        display: flex;
        align-items: flex-end;
    }
    .cmiContent {
        color: #000;
        height: auto;
        background-color: rgba(255,255,255,0.8);
        padding: 10px 0;
        font-size: 24px;
    }
    .catalogMainItem:hover .cmiContent {
        color: #000;
        background-color: rgba(255,255,255,0.8);
    }
    .catalogTitleContainer {
        display: flex;
        align-items: center;
    }
    .ctcContent {
        color: #000;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
        height: auto;
        padding: 10px 0;
    }
    .ctcTitle {
        font-weight: 600;
        font-size: 26px;
    }
    .ctcDescription {
        font-weight: 400;
        font-size: 21px;
    }
    .catalogTitleContainer:hover .ctcContent {
        color: #000;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
    }
    .footerContacts {
        flex-direction: column;
        justify-content: center;
    }
    .footerContactsItem {
        margin: 10px 0;
    }
    .footerContactsItem a:first-child {
        margin-bottom: 10px;
    }
    .footerContactsTitle {
        padding-left: 80px;
    }
    .mainHeaderTitleContainer {
        padding: 20px 0;
    }
}

@media screen and (max-width: 960px) {

    .ciArrow {
        background-image: url("../img/arrow_left.png");
        opacity: 1;
        width: 40px;
        height: 40px;
    }
    .ciLeftArrow {
        left: 10px;
    }
    .ciRightArrow {
        right: 10px;
    }
    .ciContainer {
        padding: 0;
        position: unset;
    }
    .ciWrapper {
        background: #666;
    }
    .ciButton {
        bottom: 105px;
        height: 40px;
        background: #eee;
    }
    .ciTitle {
        top: 10px;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0.8) 90%, rgba(0,0,0,0) 100%);
    }
    .ciDescription {
        bottom: 150px;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0.8) 90%, rgba(0,0,0,0) 100%);
    }
    .ciMiniatureContainer {
        bottom: 0;
    }
    .ciCloseButton {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        background: url("../img/menu_close.png") left center no-repeat;
        background-size: cover;
        z-index: 10;
    }

    .mainMenu {
        display: none;
    }
    .mainMenuMobile {
        position: fixed;
        width: 100%;
        height: 60px;
        background: rgba(0,0,0,0.9);
        padding: 0 30px;
        cursor: default;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
        z-index: 15;
    }
    .mobileMenuContainer {
        display: flex;
        flex-direction: column;
    }
    .mobileMenuButton {
        padding-left: 30px;
        margin: 10px 0;
        color: #fff;
        font-family: Caveat, sans-serif;
        font-size: 26px;
    }
    .mobileMenuButtonOpen {
        background: url("../img/menu.png") left center no-repeat;
        background-size: contain;
    }
    .mobileMenuButtonClose {
        background: url("../img/menu_close.png") left center no-repeat;
        background-size: contain;
    }
    .mainMenuPosition {
        font-size: 20px;
        margin: 5px 0;
        text-align: center;
    }
    .footerContactsTitle {
        padding-left: 50px;
    }
    .mainHeaderTitle {
        font-size: 36px;
    }
    .mainHeaderDescription {
        font-size: 26px;
    }
    .ciTitle,
    .ciDescription,
    .ciButton {
        opacity: 1;
    }
    .ciMiniatureContainer {
        overflow-x: scroll;
    }
    /*.ciArrow {*/
    /*    width: 100px;*/
    /*    height: 100px;*/
    /*}*/
    /*.ciContainer {*/
    /*    position: relative;*/
    /*}*/
    /*.ciButton {*/
    /*    position: absolute;*/
    /*    bottom: -80px;*/
    /*}*/
    .ciRightArrow {
        transform: translateY(-150%) rotate(180deg);
    }
    .ciLeftArrow {
        transform: translateY(-150%) ;
    }
    .cartButton {
        width: 200px;
    }
    .globalContactsContainer {
        bottom: 50px;
        left: 50px;
        width: 70px;
        height: 70px;
    }

}

@media screen and (max-width: 760px) {
    .cibDesc {
        display: none;
    }
    .mainHeader {
        height: 100vh;
    }
    .catalogTitleContainer {
        width: 480px;
    }
    .catalogMainContainer,
    .catalogPageContainer {
        width: 480px;
    }
    .catalogMainItem,
    .cmiBackground {
        width: 230px;
        height: 230px;
    }
    .cmiContent {
        width: 230px;
    }
    .cmiAddButton {
        width: 230px;
        height: 40px;
    }
    .catalogBackButton {
        min-width: 230px;
        max-height: 230px;
        min-height: 230px;
    }
    .footer {
        flex-direction: column;
        background: #eee;
        height: auto;
        padding-top: 20px;
        align-items: center;
    }
    .fciTime {
        display: flex;
    }
    .footerContacts {
        padding: 0;
    }
    .footerContactsTitle {
        padding: 0;
        text-align: center;
    }
    .footerInstagram {
        align-items: center;
    }
    .footerInstagramPhotos {
        justify-content: space-around;
    }
    .cartButtonsContainer {
        width: 80%;
        justify-content: space-between;
    }
    .cartButton {
        width: 150px;
        font-size: 16px;
    }
    .cibTitle {
        font-size: 14px;
    }
    .cibPicture {
        min-width: 50px;
        height: 50px;
        background-position: center center;
        background-size: cover;
    }
}

@media screen and (max-width: 480px) {
    .catalogPageContainer,
    .catalogMainContainer {
        display: flex;
        flex-direction: column;
    }
    .catalogTitleContainer {
        width: 100%;
    }

    .footer {
        padding: 50px 0 0;
    }
    .catalogBackButton {
        width: 230px;
        max-height: 100px;
        height: 100px;
        min-height: 100px;
    }
    .footerInstagramTitle {
        font-size: 18px;
    }
    .fipItem {
        margin: 0;
        width: 60px;
        height: 60px;
    }
    .orderContainer {
        overflow-y: auto;
        padding: 20px 0;
        height: auto;
        max-height: 85vh;
    }
    .cartButton {
        margin-top: 10px;
    }

    .globalContactsContainer {
        bottom: 40px;
        left: 20px;
        width: 70px;
        height: 70px;
    }
}

